<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12" sm="6" md="12">
        <listitemspage
          :selitem="selitem"
          :qSelect="Qselect"
          :qSelectall="Qselectall"
          :PageResponse="'bts'"
          :AllResponse="'allbts'"
          :headers="headers"
          :field="'CREATED_AT'"
          :filename="'BTs'"
          :sheetname="'BTs'"
          @open="OpenBTForm"
          @hrf="OpenBTForm"
          @rowselect="BTChange"
          :showedit="false"
          :key="klist"
          :Add="true"
          :del="true"
          :del_disable="bt.statut_id > 6"
          :search_elm="search"
          @delete="deleteItem"
          :list_options="true"
          :isExpire="false"
        >
        </listitemspage>
      </v-col>
    </v-row>
    <btform
      :item="bt"
      :key="kbt"
      :showForm="!isBTClosed"
      :readonly="bt.statut_id >= 7"
      :editer="editer"
      :valider="valider"
      :solder="solder"
      :open_orders="open_orders"
      @close="closeBTForm"
      @refresh="refresh"
    >
    </btform>
    <template>
      <confirmdialog ref="confirm" />
    </template>
    <v-snackbar
      v-model="snackbar"
      :timeout="snackbar_timeout"
      top
      :color="snackbar_color"
    >
      {{ snackbar_text }}
    </v-snackbar>
  </v-container>
</template>

<script>
const listitemspage = () => import("../components/ListItemsPages.vue");
const btform = () => import("../components/BTForm.vue");
const confirmdialog = () => import("../components/ConfirmDialog.vue");

import BTS from "../graphql/Production/BTS.gql";
import ALLBTS from "../graphql/Production/ALLBTS.gql";
import ACTUAL_ORDERS from "../graphql/Mouvment/ACTUAL_ORDERS.gql";
import DELETE_ORDER_BT from "../graphql/Production/DELETE_ORDER_BT.gql";

export default {
  components: {
    listitemspage,
    confirmdialog,
    btform,
  },
  props: {},
  data: () => ({
    snackbar: false,
    snackbar_timeout: 2000,
    snackbar_text: "",
    snackbar_color: "primary",
    editer: false,
    solder: false,
    valider: false,
    klist: 100,
    kbt: 300,
    search: "",
    drawer: null,
    selitem: {},
    headers: [
      {
        text: "N°",
        value: "no",
        selected: true,
      },
      {
        text: "N° BT",
        value: "no_bt",
        enum: "NO_BT",
        selected: true,
      },
      {
        text: "N° OF",
        value: "no_order",
        enum: "NO_ORDER",
        selected: true,
      },

      {
        text: "Produit",
        value: "label",
        enum: "LABEL",
        slot: "href",
        selected: true,
      },

      {
        text: "Date",
        value: "bt_date",
        enum: "BT_DATE",
        selected: true,
      },
      {
        text: "Etape",
        value: "gamme_caption",
        enum: "GAMME_CAPTION",
        selected: true,
      },

      {
        text: "Machine",
        value: "machine_name",
        enum: "MACHINE_NAME",
        selected: true,
      },
      {
        text: "Qte Lancée",
        value: "qte",
        enum: "QTE",
        align: "end",
        selected: true,
      },
      {
        text: "Qte Fabriqué",
        value: "qte_fab",
        enum: "QTE_FAB",
        align: "end",
        selected: true,
      },
      {
        text: "Statut",
        value: "statut_name",
        enum: "STATUT_NAME",
        selected: true,
      },
    ],
    open_orders: [],
    isBTClosed: true,
    bt: {},
    changed: false,
    loading: false,
  }),

  computed: {
    Qselect() {
      return BTS;
    },
    Qselectall() {
      return ALLBTS;
    },
  },
  watch: {},
  created() {},

  async mounted() {
    this.editer =
      this.$store.state.auth.includes("02082") || this.$store.state.isadmin;
    this.valider =
      this.$store.state.auth.includes("03082") || this.$store.state.isadmin;
    this.solder =
      this.$store.state.auth.includes("04082") || this.$store.state.isadmin;

    this.get_data();
  },

  methods: {
    async refresh() {
      let v = {
        where: {
          AND: [
            { column: "QTE_REST", operator: "GT", value: 0 },
            { column: "STATUT_ID", value: 7 },
          ],
        },
      };
      let r = await this.requette(ACTUAL_ORDERS, v);
      if (r) this.open_orders = r.allorders;
    },

    async get_data() {
      this.refresh();
    },
    async requette(query, v) {
      this.loading = true;
      let r;
      await this.$apollo
        .query({
          query: query,
          variables: v,
          fetchPolicy: "no-cache",
        })
        .then((data) => {
          r = data.data;
          this.loading = false;
        })
        .catch((error) => {
          this.loading = false;
          this.snackbar_text = error.message;
          this.snackbar_color = "error";
          this.snackbar = true;
        });
      return r;
    },
    async maj(query, v, r) {
      this.progress = true;
      await this.$apollo
        .mutate({
          mutation: query,
          variables: v,
        })
        .then(() => {
          if (r) this.klist++;
          this.progress = false;
        })
        .catch((error) => {
          this.loading = false;
          this.snackbar_text = error.message;
          this.snackbar_color = "error";
          this.snackbar = true;
        });
    },
    async deleteItem(item) {
      if (
        await this.$refs.confirm.open(
          "Confirmation",
          "Etês-vous sûr de vouloir supprimer cette bt?"
        )
      ) {
        let v = {
          id: item.id,
        };
        await this.maj(DELETE_ORDER_BT, v);
        await this.refresh();
        this.klist++;
      }
    },
    BTChange(item) {
      this.bt = item;
      this.selitem = item;
    },
    OpenBTForm(item) {
      this.bt = item;
      this.isBTClosed = false;
      this.kbt++;
    },
    closeBTForm(item) {
      this.selitem = item;
      this.bt = item;
      this.isBTClosed = true;
    },
  },
};
</script>
